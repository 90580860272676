import { render, staticRenderFns } from "./communityranking.vue?vue&type=template&id=5b90413c&scoped=true&"
import script from "./communityranking.vue?vue&type=script&lang=js&"
export * from "./communityranking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b90413c",
  null
  
)

export default component.exports